import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import Navbar from './components/NavBar'
import React from "react";
import Home from './pages/Home'
import SignIn from './pages/login/Sign-in';
import SignUp from './pages/login/Sign-up';
import EmailResetPass from './pages/login/EmailResetPass';
import ResetPass from './pages/login/ResetPass';
import { AuthProvider } from './pages/login/OAuth';

import ListArticles from './pages/articles/ListArticles';
import NewArticle from './pages/articles/NewArticle';

import ListClients from './pages/clients/ListClients';
import NewClient from './pages/clients/NewClient';

import NewTruck from './pages/trucks/NewTruck';
import ListTrucks from './pages/trucks/ListTrucks';
import UpdateTruck from './pages/trucks/UpdateTruck';

import ListDeliveryNotes from './pages/deliveryNotes/ListDeliveryNotes';
import NewDeliveryNoteGE from './pages/deliveryNotes/NewDeliveryNoteGE';
import DeliveryNoteDetails from './pages/deliveryNotes/DeliveryNotesDetails';

import ListProviders from './pages/providers/ListProviders';
import UpdateProvider from './pages/providers/UpdateProvider';
import NewProvider from './pages/providers/NewProvider';

import ListTrailers from './pages/trailers/ListTrailers';
import UpdateTrailer from './pages/trailers/UpdateTrailer';

import NewTruckDriver from './pages/truckDriver/NewTruckDriver';
import ListTruckDrivers from './pages/truckDriver/ListTruckDriver';
import NewBuilding from './pages/buildings/NewBuilding';
import ListBuildings from './pages/buildings/ListBuildings';
import UpdateTruckDriver from './pages/truckDriver/UpdateTruckDriver';

import ProtectedRoute from './components/ProtectedRoute';
import UpdateBuilding from './pages/buildings/UpdateBuilding';
import UpdateDeliveryNoteGE from './pages/deliveryNotes/UpdateDeliveryNoteGE';

import UserEdit from './pages/users/UserEdit';

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Navbar />
          <Box sx={{ marginTop: 10, marginLeft: 10, marginBottom: 10, marginRight: 5 }}>
            <Routes>
              <Route path='/sign-in' element={<SignIn />} />
              <Route path='/sign-up' element={<SignUp />} />
              <Route path='/reset-pass' element={<EmailResetPass />} />
              <Route path='/reset-pass/:token' element={<ResetPass />} />

              <Route element={<ProtectedRoute />}>

                <Route path='/articles/new' element={<NewArticle />} />
                <Route path='/articles/list' element={<ListArticles />} />

                <Route path='/clients/new' element={<NewClient />} />
                <Route path='/clients/list' element={<ListClients />} />

                <Route path='/trucks/new' element={<NewTruck />} />
                <Route path='/trucks/:id/update' element={<UpdateTruck />} />
                <Route path='/trucks/list' element={<ListTrucks />} />

                <Route path='/delivery_notes/new/GE' element={<NewDeliveryNoteGE />} />
                <Route path='/delivery_notes/list' element={<ListDeliveryNotes />} />
                <Route path='/delivery_notes/:id/details' element={<DeliveryNoteDetails />} />
                <Route path='/delivery_notes/:id/update/GE' element={<UpdateDeliveryNoteGE />} />

                <Route path='/providers/new' element={<NewProvider />} />
                <Route path='/providers/:id/update' element={<UpdateProvider />} />
                <Route path='/providers/list' element={<ListProviders />} />

                <Route path='/trailers/:id/update' element={<UpdateTrailer />} />
                <Route path='/trailers/list' element={<ListTrailers />} />

                <Route path='/truck_drivers/new' element={<NewTruckDriver />} />
                <Route path='/truck_drivers/:id/update' element={<UpdateTruckDriver />} />
                <Route path='/truck_drivers/list' element={<ListTruckDrivers />} />

                <Route path='/buildings/new' element={<NewBuilding />} />
                <Route path='/buildings/list' element={<ListBuildings />} />
                <Route path='/buildings/:id/update' element={<UpdateBuilding />} />

                <Route path='/users/me' element={<UserEdit />} />

              </Route>
              <Route path='*' element={<Home />} />

            </Routes>
          </Box>
        </AuthProvider>

      </BrowserRouter>
    </>
  );
}

export default App;
