import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import { useAuth } from "../login/OAuth";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Checkbox,
    Autocomplete,
    TextField,
    Paper,
    Button,
    CircularProgress,
    Typography
} from "@mui/material";


function ListClients() {
    const [clients, setClients] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [documentation, setDocumentation] = useState(null);
    const [name, setName] = useState(null);

    const [fetchReset, setFetchReset] = useState(false);

    const [selectedClientsIds, setSelectedClientsIds] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const [sortByAlphabet, setSortByAlphabet] = useState(false);

    const fetchClients = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            if (selectedBuilding) {
                params.building_id = selectedBuilding.id;
            } if (name) {
                params.name = name;
            } if (documentation) {
                params.documentation = documentation;
            }
            if (sortByAlphabet) {
                params.sort_by_name = true;
            }

            const result = await api().get("/clients/list/active", { params });
            setClients(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
            setLoading(false);
        }
    }, [api, name, documentation, selectedBuilding]);

    const syncroNav = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().post("/clients/nav/import/");
            fetchClients();
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchBuildings = useCallback(async () => {
        try {
            const result = await api().get(`/buildings`);
            setBuildings(result.data);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);
    const handleBuilding = (event, newValue) => {
        if (newValue) {
            setSelectedBuilding(newValue);
        }
    };

    useEffect(() => {
        fetchBuildings();
        fetchClients();
    }, []);

    useEffect(() => {
        if (fetchReset) {
            fetchClients();
            setFetchReset(false);
        }
    }, [fetchReset]);

    const handleApplyFilter = () => {
        fetchClients();
    };
    const handleResetFilters = () => {
        setName(null);
        setDocumentation(null);
        setSelectedBuilding(null);
        setSortByAlphabet(null);
        setFetchReset(true);
    };
    const handleDocumentation = (event) => {
        setDocumentation(event.target.value);
    };
    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleSelectAllCheckboxChange = () => {
        setSelectAllChecked(!selectAllChecked);
        if (!selectAllChecked) {
            setSelectedClientsIds(clients.map(item => item.id));
        } else {
            setSelectedClientsIds([]);
        }
    };
    const handleSortByAlphabetChange = () => {
        setSortByAlphabet(!sortByAlphabet);
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Button variant="contained" color="primary" disabled={loading} onClick={syncroNav}>
                        Importar de Navision
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleApplyFilter}>
                        Aplicar Filtros
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleResetFilters} style={{ marginLeft: '20px' }}>
                        Borrar Filtros
                    </Button>
                </Grid>
            </Grid>

            <br />
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={3}>
                    <Autocomplete
                        options={buildings}
                        getOptionLabel={(option) => option.name?.toString()}
                        value={selectedBuilding}
                        onChange={handleBuilding}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Obras"
                                style={{ width: '100%' }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3} sm={2}>
                    <TextField
                        fullWidth
                        label="Documentación"
                        name="documentation"
                        value={documentation || ""}
                        onChange={handleDocumentation}
                    />
                </Grid>
                <Grid item xs={3} sm={2}>
                    <TextField
                        fullWidth
                        label="Nombre"
                        name="name"
                        value={name || ""}
                        onChange={handleName}
                    />
                </Grid>
                <Grid item xs={3} container alignItems="center"> 
                    <Checkbox
                        checked={sortByAlphabet}
                        onChange={handleSortByAlphabetChange}
                        color="primary"
                    />
                    <Typography variant="body1">Ordenar alfabéticamente</Typography>
                </Grid>


            </Grid>
            <br />
            <br />
            <TableContainer component={Paper}>
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </div>
                )}
                {!loading && (
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>Nombre</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>Documentación</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>Dirección</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>CP</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>Población</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clients.map(client => (
                                <TableRow key={client.id}>
                                    
                                    <TableCell component="th" scope="row">
                                        {client.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {client.documentation}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {client.address}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {client.cp}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {client.city}
                                    </TableCell>
                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <br />
            <br />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    )
}
export default ListClients;