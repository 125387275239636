import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    Button,
    TextField,
    Grid,
    CircularProgress,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";

function UpdateTrailer() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [trailerForm, setTrailerForm] = useState({
        license_plate: null,
        trailer_type: null
    });

    useEffect(() => {
        const fetchTrailer = async () => {
            try {
                const result = await api().get(`/trailers/${id}`);
                setTrailerForm(result.data);
                setLoading(false);
            } catch (error) {
                setError("Error obteniendo trailer");
                setLoading(false);
        }
        };

        fetchTrailer();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTrailerForm({ ...trailerForm, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const result = await api().patch(`/trailers/${id}/update`, trailerForm);
            if (result.status < 300) {
                alert('Trailer actualizado correctamente');
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container justifyContent="center">
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Grid item xs={12} sm={8} md={6} lg={4}>

                    <Typography variant="h5" gutterBottom>
                        Actualizar remolque
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Matrícula"
                                        required
                                        name="license_plate"
                                        value={trailerForm.license_plate}
                                        onChange={handleChange}
                                        margin="normal"
                                        InputLabelProps={{ shrink: !!trailerForm.license_plate }}
                                    />
                                </Grid>
                                <FormControl fullWidth margin="normal">
                                <InputLabel id="trailer-type-label">Tipo</InputLabel>
                                <Select
                                    labelId="trailer-type-label"
                                    id="trailer_type"
                                    name="trailer_type"
                                    InputLabelProps={{ shrink: !!trailerForm.trailer_type }}
                                    value={trailerForm.trailer_type}
                                    onChange={handleChange}
                                    label="Tipo"
                                >
                                    <MenuItem value="Carrillero_3_ejes_rigidos">CARRILLERO 3 EJES RIGIDOS</MenuItem>
                                    <MenuItem value="Bañera_2_ejes">BAÑERA 2 EJES</MenuItem>
                                    <MenuItem value="Bañera_3_ejes">BAÑERA 3 EJES</MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                        </Grid>
                        <br />
                        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                            Actualizar
                        </Button>
                    </form>
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>

    );
}

export default UpdateTrailer;
