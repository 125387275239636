import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../login/OAuth";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Checkbox,
    Typography,
    Grid,
    CircularProgress
} from "@mui/material";


function ListArticles() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { api } = useAuth();

    const [selectedArticlesIds, setSelectedArticlesIds] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [sortByAlphabet, setSortByAlphabet] = useState(false);

    const fetchArticles = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            const result = await api().get("/articles/list/active", { params });
            setArticles(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
            setLoading(false);
        }
    }, [api]);

    const fetchArticlesNavision = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().post("/articles/nav/import");
            fetchArticles();
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchArticles();
        setSelectedArticlesIds([]);
        setSelectAllChecked(false);
    }, []);

    const handleSelectAllCheckboxChange = () => {
        setSelectAllChecked(!selectAllChecked);
        if (!selectAllChecked) {
            setSelectedArticlesIds(articles.map(item => item.id));
        } else {
            setSelectedArticlesIds([]);
        }
    };
    const handleCheckboxChange = (event, id) => {
        const stringId = id.toString();
        if (event.target.checked) {
            setSelectedArticlesIds([...selectedArticlesIds, stringId]);
        } else {
            setSelectedArticlesIds(selectedArticlesIds.filter(itemId => itemId !== stringId));
        }
    };

    const sendArticleToNav = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().post("/articles/nav/export", selectedArticlesIds);
            fetchArticles();
            setLoading(false);
            alert('Articulos enviados con éxito')
        } catch (error) {
            setLoading(false);
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const handleSortByAlphabetChange = () => {
        setSortByAlphabet(!sortByAlphabet);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    const filteredArticles = articles
        .filter(article => {
            const lowerCaseName = article.name.toLowerCase();
            const matchesName = lowerCaseName.includes(name.toLowerCase());
            const matchesCode = article.code.toString().includes(code);
            return matchesName && matchesCode;
        })
        .sort((a, b) => {
            if (sortByAlphabet) {
                return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
            }
            return 0;
        });

    return (
        <div>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={3} sm={2}>
                    <TextField
                        fullWidth
                        label="Nombre"
                        name="name"
                        value={name || ""}
                        onChange={handleNameChange}
                    />
                </Grid>
                <Grid item xs={3} sm={2}>
                    <TextField
                        fullWidth
                        label="Código"
                        name="code"
                        value={code || ""}
                        onChange={handleCodeChange}
                    />
                </Grid>
                <Grid item container xs={3} sm={2} alignItems="center">
                    <Checkbox
                        checked={sortByAlphabet}
                        onChange={handleSortByAlphabetChange}
                        color="primary"
                    />
                    <Typography variant="body1">Ordenar alfabéticamente</Typography>
                </Grid>
            </Grid>
            <br></br>
            <br></br>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Button variant="contained" color="primary" disabled={loading} onClick={fetchArticlesNavision}>
                        Importar de Navision
                    </Button>

                </Grid>
                <Grid item xs={12}>

                    {loading && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </div>
                    )}
                    {!loading && (

                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Nombre</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Código</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Precio</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Disponibilidad</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Unidad</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>En navision</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredArticles.map(article => (
                                        <TableRow key={article.id}>
                                            <TableCell component="th" scope="row">
                                                {article.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {article.code}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {article.price} €
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {article.stock}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {article.unit}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {article.in_nav ? 'Sí' : 'No'}
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    <br />

                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}
export default ListArticles;