import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { TextField, Button, Paper, Typography, Grid, CircularProgress, Autocomplete, TableCell, Table, TableRow, TableBody, TableHead, TableContainer } from '@mui/material';

function UpdateBuilding() {
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [clients, setClients] = useState([]);
    const [clientSelected, setClientSelected] = useState(null);
    const [buildingClients, setBuildingClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();

    const [buildingForm, setBuildingForm] = useState({
        name: null,
    });

    const fetchClients = useCallback(async () => {
        try {
            const result = await api().get('/clients/all/active');
            if (result.status < 300) {
                setClients(result.data);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchBuilding = useCallback(async () => {
        try {
            const result = await api().get(`/buildings/${id}`);
            setBuildingForm(result.data);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo las obras");
            setLoading(false);
        }
    }, [id, api]);

    const fetchBuildingClients = useCallback(async () => {
        try {
            const result = await api().get(`/buildings/${id}/clients`);
            setBuildingClients(result.data);
        } catch (error) {
            setError("Error obteniendo las relaciones");
        }
    }, [id, api]);

    useEffect(() => {
        fetchClients();
        fetchBuilding();
        fetchBuildingClients();
    }, [id]);

    const handleClient = (event, newValue) => {
        if (newValue) {
            setClientSelected(newValue);
        }
    };

    const handleBuildingChange = (event) => {
        const { name, value } = event.target;
        setBuildingForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (buildingForm.name == null || buildingForm.name === "") {
            setError("Debe indicar un nombre");
            return;
        }

        try {
            setLoading(true);
            const result = await api().patch(`/buildings/${id}/update`, buildingForm);
            if (result.status < 300) {
                alert('Obra actualizada correctamente');
                fetchBuilding();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    const addClients = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const result = await api().post(`/buildings/${id}/${clientSelected.id}/associate`);
            if (result.status < 300) {
                alert('Cliente añadido correctamente');
                fetchBuilding();
                fetchBuildingClients();
                setClientSelected(null);
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    }

    const deleteClient = async (clientId) => {
        try {
            setLoading(true);

            const result = await api().post(`/buildings/${id}/clients/remove?client_id=${clientId}`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchBuilding();
                    fetchBuildingClients();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Grid container justifyContent="center">

                    <Grid item xs={12} sm={8} md={6} lg={4}>

                        <Typography variant="h5" gutterBottom>
                            Actualizar obra
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            required
                                            value={buildingForm.name}
                                            onChange={handleBuildingChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!buildingForm.name }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                Actualizar
                            </Button>
                            <br />
                            <br />
                            <Autocomplete
                                options={clients}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={clientSelected}
                                onChange={handleClient}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Clientes"
                                        fullWidth
                                    />
                                )}
                            />
                            <br></br>
                            <Button variant="contained" color="success" onClick={addClients} disabled={loading || !clientSelected}>
                                Añadir cliente
                            </Button>
                            <br></br>

                            {buildingClients && (
                                <>
                                    <br></br>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Clientes asociados
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID Obra - Cliente</TableCell>
                                                    <TableCell>Nombre</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {buildingClients.map((client) => (
                                                    <TableRow key={client.id}>
                                                        <TableCell>{client.id}</TableCell>
                                                        <TableCell>{client.client.name}</TableCell>
                                                        
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            )}
                            <br></br>


                        </form>

                        {error && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                {error}
                            </Typography>
                        )}
                    </Grid>
                </Grid >
            )}
        </>
    );
}

export default UpdateBuilding;
