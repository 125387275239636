import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    TextField,
    Button,
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { FaTrash } from 'react-icons/fa';

function UpdateTruck() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openTareDialog, setOpenTareDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [editTareItemId, setEditTareItemId] = useState(null);
    const [newTare, setNewTare] = useState('');
    const [changingTare, setChangingTare] = useState(false); // Nuevo estado para el cambio de tara
    const [truckForm, setTruckForm] = useState({
        license_plate: null,
        tare: null
    });
    const [trailers, setTrailers] = useState([]);

    const fetchTruck = async () => {
        try {
            const result = await api().get(`/trucks/${id}`);
            setTruckForm(result.data[0]);
            setTrailers(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo camión");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTruck();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTruckForm({ ...truckForm, [name]: value });
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleCloseTareDialog = () => {
        setOpenTareDialog(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const result = await api().patch(`/trucks/${id}/update`, truckForm);
            if (result.status < 300) {
                alert('Camión actualizado correctamente');
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleOpenTareDialog = (itemId) => {
        setEditTareItemId(itemId);
        setOpenTareDialog(true);
    };

    const handleDissasociateTrailer = async () => {
        try {
            const result = await api().patch(`/trucks/${id}/${deleteItemId}/dissasociate`);
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchTruck();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }
    };

    const handleTareChange = async () => {
        setChangingTare(true); // Set changingTare to true when the request starts
        try {
            const result = await api().patch(`/trucks/${id}/update_tare`, { trailer_id: editTareItemId, new_tare: newTare });
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchTruck();
                    handleCloseTareDialog();
                    setNewTare('');
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        } finally {
            setChangingTare(false); // Set changingTare to false when the request completes
        }
    };

    return (
        <Grid container justifyContent="center">
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Typography variant="h5" gutterBottom>
                        Actualizar camión
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Matrícula"
                                    required
                                    name="license_plate"
                                    value={truckForm.license_plate}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{ shrink: !!truckForm.license_plate }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                            Actualizar
                        </Button>
                    </form>
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                    <Typography variant="h6" gutterBottom style={{ marginTop: 20 }}>
                        Remolques
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Matrícula</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Tara</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Acciones</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trailers.map((trailer, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {trailer.trailer_license_plate}
                                        </TableCell>
                                        <TableCell>
                                            {trailer.tare} kg
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleOpenTareDialog(trailer.id)} variant="contained" color="primary" sx={{ mr: 2 }}>
                                                Cambiar Tara
                                            </Button>
                                            <Button onClick={() => handleOpenDeleteDialog(trailer.id)} variant="contained" color="error">
                                                <FaTrash />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>¿Estás seguro?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Volver
                                        </Button>
                                        <Button onClick={handleDissasociateTrailer} color="error">
                                            Eliminar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog open={openTareDialog} onClose={handleCloseTareDialog}>
                                    <DialogTitle>Cambiar Tara</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Introduzca la nueva tara:
                                        </DialogContentText>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            label="Nueva Tara"
                                            type="number"
                                            fullWidth
                                            value={newTare}
                                            onChange={(e) => setNewTare(e.target.value)}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseTareDialog} color="primary">
                                            Cancelar
                                        </Button>
                                        <Button onClick={handleTareChange} color="primary" disabled={changingTare}>
                                            {changingTare ? <CircularProgress size={24} /> : 'Cambiar'}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
}

export default UpdateTruck;
