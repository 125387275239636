import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/OAuth"
import BasicDatePicker from "../../components/BasicDatePicker";

function UserEdit() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { api } = useAuth();

  const [userForm, setUserForm] = useState({
    username: '',
    name: '',
    surname: '',
    email: '',
    country: '',
    born_date: null,
  });
  const fetchUser = async () => {
    try {
      const result = await api().get(`/users/get/me`);
      setUserForm(result.data);
    } catch (error) {
      setError("Error obteniendo usuario");
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserForm({ ...userForm, [name]: value });
  };
  const handleDate = (event) => {
    setUserForm({ ...userForm, born_date: event });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(userForm.email)) {
      alert("Email no válido")
    }
    else {
      setLoading(true);

      try {
        const result = await api().patch('/users/update/me', userForm);
        if (result.status < 300) {
          alert('Usuario actualizado correctamente');
          setError(null);
        } else {
          setError(`Error ${result.status}: ${result.data.detail}`);
        }
        navigate("/me");
      } catch (error) {
        setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6} lg={4}>

        <Typography variant="h5" align="center">
          Actualizar usuario
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  label="Username"
                  variant="outlined"
                  name="username"
                  fullWidth
                  margin="normal"
                  value={userForm.username}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nombre"
                  variant="outlined"
                  name="name"
                  fullWidth
                  margin="normal"
                  value={userForm.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Apellido"
                  variant="outlined"
                  name="surname"
                  fullWidth
                  margin="normal"
                  value={userForm.surname}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Correo electrónico"
                  variant="outlined"
                  name="email"
                  fullWidth
                  margin="normal"
                  value={userForm.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <BasicDatePicker
                  fullWidth
                  id="born_date"
                  label="Fecha de nacimiento"
                  name="born_date"
                  value={userForm.born_date}
                  onChange={(event) => {
                    handleDate(event);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || !userForm.name || !userForm.surname || !userForm.email }
          >
            Actualizar Usuario
          </Button>

        </form>
        {
          error && (
            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
              {error}
            </Typography>
          )
        }
      </Grid >
    </Grid >
  );
}

export default UserEdit;
