import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Switch,
    InputLabel,
    MenuItem,
    Grid,
    FormControl,
    Select,
    Paper,
    Typography,
} from "@mui/material";

function NewClient() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { api } = useAuth();

    const [clientForm, setClientForm] = useState({
        name: null,
        cp: null,
        address: null,
        documentation: null,
        doc_type: null,
        city: null,
        selectable: false,
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setClientForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (event) => {
        const { name, checked } = event.target;
        setClientForm(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const createClient = async (event) => {
        event.preventDefault();
        if (!clientForm.name) {
            setError("Por favor, añada un nombre");
            return;
        }
        
        try {
            setLoading(true);

            const result = await api().post('/clients/new', clientForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Cliente creado con éxito");
                    navigate(`/clients/list`);
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);

        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo cliente
                </Typography>
                <br></br>
                <form onSubmit={createClient}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    name="name"
                                    value={clientForm.name}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Dirección"
                                    name="address"
                                    value={clientForm.address}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl 
                                variant="outlined"
                                margin={"1"}
                                style={{ width: "100%" }}
                            >
                                    <InputLabel id="document-type-label">
                                        Documentación
                                    </InputLabel>
                                    <Select
                                        labelId="document-type-label"
                                        id="document-type"
                                        label='Tipo documentación'
                                        value={clientForm.doc_type}
                                        onChange={handleChange}
                                        margin="normal"
                                        name="doc_type"
                                        inputProps={{ id: 'document-type' }}
                                    >
                                        <MenuItem value="dni">DNI</MenuItem>
                                        <MenuItem value="nie">NIE</MenuItem>
                                        <MenuItem value="extranjero">Extranjero</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Documentación"
                                    name="documentation"
                                    value={clientForm.documentation}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="CP"
                                    name="cp"
                                    type="number"
                                    value={clientForm.cp}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Población"
                                    name="city"
                                    value={clientForm.city}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Bloqueado</Grid>
                                        <Grid item>
                                            <Switch
                                                name="selectable"
                                                checked={clientForm.selectable}
                                                onChange={handleSelectChange}
                                                color="primary"
                                            />
                                        </Grid>
                                        <Grid item>No bloqueado</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            Crear
                        </Button>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewClient