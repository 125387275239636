import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { Paper, Grid, Box, Typography, Button, CircularProgress, Divider, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { LocalShipping, Person, WorkOutline} from '@mui/icons-material';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

function DeliveryNoteDetails() {
    const { id } = useParams();
    const [deliveryNote, setDeliveryNote] = useState(null);
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDeliveryNote = async () => {
            try {
                const response = await api().get(`/delivery_notes/${id}`);
                const [note, price] = response.data;
                setDeliveryNote(note);
                setLoading(false);
            } catch (error) {
                setError('Error obteniendo albarán');
            }
        };
        if (deliveryNote === null) {
            fetchDeliveryNote();
        }
    }, [id, api]);

    const generatePDF = async () => {
        try {
            const response = await api().get(`/delivery_notes/${id}/generate-pdf`, { responseType: 'blob' });
            const pdfUrl = URL.createObjectURL(response.data);
            window.open(pdfUrl, '_blank');
        } catch (error) {
            setError('Error generando PDF');
        }
    };

    const generateCSV = async () => {
        try {
            const response = await api().get(`/delivery_notes/${id}/generate-csv`, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${deliveryNote.delivery_note_number}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            setError('Error generando CSV');
        }
    }

    return (
        <div style={{ margin: '20px' }}>
            <Typography variant="h4" gutterBottom align="center">Detalles del albarán</Typography>
            <Divider style={{ marginBottom: '20px' }} />
            {loading ? (
                <CircularProgress />
            ) : (
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">Nº de albarán: {deliveryNote.delivery_note_number}</Typography>
                            <Typography variant="subtitle1">Fecha de salida: {new Date(deliveryNote.departure_date).toLocaleString()}</Typography>
                            {deliveryNote.order.license_plate_b && <Typography variant="subtitle1">Matrícula: {deliveryNote.order.license_plate_b}</Typography>}
                            <Typography variant="subtitle1">Bruto: {deliveryNote.gross}</Typography>
                            <Typography variant="subtitle1">Tara: {deliveryNote.gross - deliveryNote.net}</Typography>
                            <Typography variant="subtitle1">Neto: {deliveryNote.net}</Typography>
                            {deliveryNote.observations && <Typography variant="subtitle1">Observaciones: {deliveryNote.observations}</Typography>}
                            <Box display="flex" flexDirection="column">
                                <Button variant="contained" onClick={generatePDF} style={{ marginTop: '20px', backgroundColor: '#4caf50', color: 'white', width: '150px' }}>Generar PDF</Button>
                                <Button variant="contained" onClick={generateCSV} style={{ marginTop: '20px', backgroundColor: '#4caf50', color: 'white', width: '150px' }}>Generar CSV</Button>
                                
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6" gutterBottom>Detalles del Pedido</Typography>
                            <List>
                                {deliveryNote.order.truck_driver && (
                                    <ListItem>
                                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                                        <ListItemText primary={`Transportista: ${deliveryNote.order.truck_driver.name}`} />
                                    </ListItem>
                                )}
                                {deliveryNote.order.truck && (
                                    <ListItem>
                                        <ListItemIcon><LocalShipping /></ListItemIcon>
                                        <ListItemText primary={`Camión: ${deliveryNote.order.truck.license_plate}`} />
                                    </ListItem>
                                )}
                                {deliveryNote.trailer && (
                                    <ListItem>
                                        <ListItemIcon><RvHookupIcon /></ListItemIcon>
                                        <ListItemText primary={`Remolque: ${deliveryNote.trailer.license_plate}`} />
                                    </ListItem>
                                )}
                                {deliveryNote.order.client && (
                                    <ListItem>
                                        <ListItemIcon><Person /></ListItemIcon>
                                        <ListItemText primary={`Cliente: ${deliveryNote.order.client.name}`} />
                                    </ListItem>
                                )}
                                {deliveryNote.building && (
                                    <ListItem>
                                        <ListItemIcon><WorkOutline /></ListItemIcon>
                                        <ListItemText primary={`Obra: ${deliveryNote.building.name}`} />
                                    </ListItem>
                                )}
                                <Divider />
                                <br />

                                <Typography variant="h6" gutterBottom>Artículo</Typography>
                                {deliveryNote.order.articles.map((article) => (
                                    <ListItem key={article.id}>
                                        <ListItemText
                                            primary={article.article.name}
                                            secondary={`Cantidad: ${article.amount}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                </Paper>

            )}
        </div>
    );
}

export default DeliveryNoteDetails;
