import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../../pages/login/OAuth";
import { FaInfoCircle } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    TextField,
    CircularProgress,
    Button,
    Checkbox,
    Typography
} from "@mui/material";


function ListProviders() {
    const [providers, setProviders] = useState([]);
    const [error, setError] = useState(null);
    const { api } = useAuth();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [documentation, setDocumentation] = useState("");

    //const [selectedProvidersIds, setSelectedProvidersIds] = useState([]);
    //const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [sortByAlphabet, setSortByAlphabet] = useState(false);

    const fetchProviders = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            const result = await api().get("/providers/list/active", { params });
            setProviders(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchProviders();
        //setSelectedProvidersIds([]);
        //setSelectAllChecked(false);
    }, []);

    /*
    const handleSelectAllCheckboxChange = () => {
        setSelectAllChecked(!selectAllChecked);
        if (!selectAllChecked) {
            setSelectedProvidersIds(providers.map(item => item.id));
        } else {
            setSelectedProvidersIds([]);
        }
    };
    const handleCheckboxChange = (event, id) => {
        const stringId = id.toString();
        if (event.target.checked) {
            setSelectedProvidersIds([...selectedProvidersIds, stringId]);
        } else {
            setSelectedProvidersIds(selectedProvidersIds.filter(itemId => itemId !== stringId));
        }
    };
    const sendProvidersToNav = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().post("/providers/nav/export", selectedProvidersIds);
            setLoading(false);
            alert('Transportistas enviados con éxito')
        } catch (error) {
            setLoading(false);
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api,selectedProvidersIds]);
 
    const fetchProvidersNavision = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().post("/providers/nav/import");
            fetchProviders();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);
    */


    const handleSortByAlphabetChange = () => {
        setSortByAlphabet(!sortByAlphabet);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleDocumentationChange = (event) => {
        setDocumentation(event.target.value);
    };

    const filteredProviders = providers
    .filter(provider => {
        const lowerCaseName = provider.name.toLowerCase();
        const matchesName = lowerCaseName.includes(name.toLowerCase());
        const lowerCaseDocumentation = provider.documentation.toLowerCase();
        const matchesDocumentation = lowerCaseDocumentation.includes(documentation.toLowerCase());
        return matchesName && matchesDocumentation;
    })
    .sort((a, b) => {
        if (sortByAlphabet) {
            return a.name.localeCompare(b.name);
        }
        return 0;
    });

    return (
        <div>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={3} sm={2}>
                    <TextField
                        fullWidth
                        label="Nombre"
                        name="name"
                        value={name || ""}
                        onChange={handleNameChange}
                    />
                </Grid>
                <Grid item xs={3} sm={2}>
                    <TextField
                        fullWidth
                        label="Documentación"
                        name="documentation"
                        value={documentation || ""}
                        onChange={handleDocumentationChange}
                    />
                </Grid>
                <Grid item container xs={3} sm={2} alignItems="center">
                    <Checkbox
                        checked={sortByAlphabet}
                        onChange={handleSortByAlphabetChange}
                        color="primary"
                    />
                    <Typography variant="body1">Ordenar alfabéticamente</Typography>
                </Grid>
            </Grid>
            <br></br>
            <br></br>
            {loading ? (
                <Grid container justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>Nombre </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>Documentación</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>Dirección</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" gutterBottom>Acciones</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredProviders.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item.documentation}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item.address}
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/providers/${item.id}/update`} >
                                            <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                                <FaInfoCircle />
                                            </Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <br />
            <br />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    )
}
export default ListProviders;