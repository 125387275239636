import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    TextField,
    DialogTitle,
    CircularProgress,
    DialogActions,
    Typography
} from "@mui/material";


function ListTrailers() {
    const [trailers, setTrailers] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { api } = useAuth();
    const [licensePlate, setLicensePlate] = useState("");
    const [type, setType] = useState("");

    const fetchTrailers = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/trailers/all/active");
            setTrailers(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])

    useEffect(() => {
        fetchTrailers();
    }, []);

    const handleDeleteItem = async () => {
        try {
            const result = await api().patch(`/trailers/${deleteItemId}/disable`)
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchTrailers();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }

    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleLicensePlateChange = (event) => {
        setLicensePlate(event.target.value);
    };
    const handleChangeType = (event) => {
        const { name, value } = event.target;
        setType(value);
    };

    const filteredTrailers = trailers.filter(trailer => {
        const matchesLicensePlate = trailer.license_plate.toLowerCase().includes(licensePlate.toLowerCase());
        if (type && type != "Todos"){
            const matchesType = trailer.trailer_type === type;
            return matchesLicensePlate && matchesType;
        }else{
            return matchesLicensePlate;
        }
    });
    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={3} sm={2}>
                            <TextField
                                fullWidth
                                label="Matrícula"
                                name="license_plate"
                                value={licensePlate || ""}
                                onChange={handleLicensePlateChange}
                            />
                        </Grid>
                        <Grid item xs={3} sm={2}>
                            <FormControl fullWidth>
                                <InputLabel id="trailer-type-label">Tipo</InputLabel>
                                <Select
                                    labelId="trailer-type-label"
                                    id="trailer_type"
                                    name="trailer_type"
                                    value={type}
                                    onChange={handleChangeType}
                                    label="Tipo"
                                >
                                    <MenuItem value="Carrillero_3_ejes_rigidos">CARRILLERO 3 EJES RIGIDOS</MenuItem>
                                    <MenuItem value="Bañera_2_ejes">BAÑERA 2 EJES</MenuItem>
                                    <MenuItem value="Bañera_3_ejes">BAÑERA 3 EJES</MenuItem>
                                    <MenuItem value="Camion_15_tn">CAMION 15 TN</MenuItem>
                                    <MenuItem value= "Todos" >Todos</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <TableContainer component={Paper} sx={{ maxWidth: 800, margin: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Matrícula</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Tipo</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="h6" gutterBottom>Acciones</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTrailers.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row">
                                            {item.license_plate}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.trailer_type === 'Carrillero_3_ejes_rigidos' ? 'CARRILLERO 3 EJES RIGIDOS' :
                                                item.trailer_type === 'Bañera_2_ejes' ? 'BAÑERA 2 EJES' :
                                                    item.trailer_type === 'Camion_15_tn' ? 'CAMIÓN 15 TN' :
                                                        item.trailer_type === 'Bañera_3_ejes' ? 'BAÑERA 3 EJES' : item.trailer_type}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Link to={`/trailers/${item.id}/update`}>
                                                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                                    <FaInfoCircle />
                                                </Button>
                                            </Link>
                                            <Button onClick={() => handleOpenDeleteDialog(item.id)} variant="contained" color="error">
                                                <FaTrash />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>¿Estás seguro?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Volver
                                        </Button>
                                        <Button onClick={handleDeleteItem} color="error">
                                            Eliminar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>

            )}
            <br />
            <br />

            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    )
}
export default ListTrailers;