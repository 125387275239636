import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    Grid,
    TextField,
    Checkbox,
    Typography,
    CircularProgress
} from "@mui/material";

function ListBuildings() {
    const [buildings, setBuildings] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { api } = useAuth();
    const [sortByAlphabet, setSortByAlphabet] = useState(false);
    const [name, setName] = useState(null);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            if (name) {
                params.name = name;
            }
            if (sortByAlphabet) {
                params.sort_by_name = true;
            }
            const result = await api().get('/buildings/list/active', { params });
            if (result.status < 300) {
                setBuildings(result.data);
                setLoading(false);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
                setLoading(false);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchBuildings();
    }, []);

    const handleDeleteItem = async () => {
        try {
            const result = await api().patch(`/buildings/${deleteItemId}/disable`)
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchBuildings();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }

    };
    const handleApplyFilter = () => {
        fetchBuildings();
    };

    const handleSortByAlphabetChange = () => {
        setSortByAlphabet(!sortByAlphabet);
    };
    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleName = (event) => {
        setName(event.target.value);
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={3} sm={2}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={name || ""}
                                onChange={handleName}
                            />
                        </Grid>
                        <Grid item xs={3} container alignItems="center">
                            <Checkbox
                                checked={sortByAlphabet}
                                onChange={handleSortByAlphabetChange}
                                color="primary"
                            />
                            <Typography variant="body1">Ordenar alfabéticamente</Typography>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end">
                            <Button variant="contained" color="primary" onClick={handleApplyFilter}>
                                Aplicar Filtros
                            </Button>
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="h6" gutterBottom>Nombre</Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="h6" gutterBottom>Clientes</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="h6" gutterBottom>Acciones</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {buildings.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row">
                                            {item.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.clients.map(client => (
                                                <Typography key={client} variant="body1">{client}</Typography>
                                            ))}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Link to={`/buildings/${item.id}/update`}>
                                                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                                    <FaInfoCircle />
                                                </Button>
                                            </Link>
                                            <Button onClick={() => handleOpenDeleteDialog(item.id)} variant="contained" color="error">
                                                <FaTrash />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>¿Estás seguro?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteItem} color="error">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <br />
            <br />
            <br />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
}

export default ListBuildings;
