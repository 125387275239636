import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    CircularProgress
} from "@mui/material";

function NewBuilding() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { api } = useAuth();

    const [buildingForm, setBuildingForm] = useState({
        name: null,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBuildingForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const createBuilding = async (event) => {
        event.preventDefault();

        if (buildingForm.name == null || buildingForm.name === "") {
            setError("Debe indicar un nombre");
            return;
        }

        try {
            setLoading(true);

            const result = await api().post('/buildings/new', buildingForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Obra creada correctamente");
                    navigate(`/buildings/list`);
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);

        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                    <Typography component="h2" variant="h5">
                        Nueva obra
                    </Typography>
                    <br></br>
                    <form onSubmit={createBuilding}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        name="name"
                                        value={buildingForm.name}
                                        onChange={handleChange}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                Crear obra
                            </Button>
                        </Grid>
                    </form>
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                </Paper >
            )}
        </div>
    );
}

export default NewBuilding