import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    Autocomplete,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from "@mui/material";

function NewTruck() {
    const [loading, setLoading] = useState(false);
    const { api } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [providers, setProviders] = useState([]);
    const [providerSelected, setProviderSelected] = useState();
    const [truckForm, setTruckForm] = useState({
        truck_license_plate: null,
        trailer_license_plate: null,
        tare: null,
        provider_id: null,
        trailer_type: null,
    });


    const fetchProviders = useCallback(async () => {
        try {
            const result = await api().get('/providers/all/active');
            if (result.status < 300) {
                setProviders(result.data);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])

    useEffect(() => {
        fetchProviders();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTruckForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleProvider = (event, newValue) => {
        if (newValue) {
            setProviderSelected(newValue);
            setTruckForm(prevState => ({
                ...prevState,
                provider_id: newValue.id,
            }));
        } else {
            setProviderSelected(null);
        }
    };

    const createTruck = async (event) => {
        event.preventDefault();
        if (!truckForm.truck_license_plate) {
            setError("Por favor, añada una matrícula de camión");
            return;
        }
        if (!truckForm.trailer_license_plate) {
            setError("Por favor, añada una matrícula de camión");
            return;
        }
        if (!truckForm.tare) {
            setError("Por favor, añada la tara");
            return;
        }
        if (truckForm.provider_id == null) {
            setError("Debe seleccionar un cliente o un transportista");
            return;
        }

        try {
            setLoading(true);

            const result = await api().post('/trucks/new', truckForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Camión creado correctamente");
                    navigate(`/trucks/list`);
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);

        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo camión
                </Typography>
                <br></br>
                <form onSubmit={createTruck}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Matrícula del camión"
                                    name="truck_license_plate"
                                    value={truckForm.truck_license_plate}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    margin="normal"
                                    options={providers}
                                    getOptionLabel={(option) => option.name?.toString()}
                                    value={providerSelected}
                                    onChange={handleProvider}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Transportista"
                                            margin="normal"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Matrícula del remolque"
                                    name="trailer_license_plate"
                                    value={truckForm.trailer_license_plate}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="trailer-type-label">Tipo del remolque</InputLabel>
                                <Select
                                    labelId="trailer-type-label"
                                    id="trailer_type"
                                    name="trailer_type"
                                    value={truckForm.trailer_type}
                                    onChange={handleChange}
                                    label="Tipo"
                                >
                                    <MenuItem value="Carrillero_3_ejes_rigidos">CARRILLERO 3 EJES RIGIDOS</MenuItem>
                                    <MenuItem value="Bañera_2_ejes">BAÑERA 2 EJES</MenuItem>
                                    <MenuItem value="Bañera_3_ejes">BAÑERA 3 EJES</MenuItem>
                                    <MenuItem value="Camion_15_tn">CAMION 15 TN</MenuItem>
                                </Select>
                            </FormControl>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Tara de la combinación (en kilogramos)"
                                    name="tare"
                                    type="number"
                                    value={truckForm.tare}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            Crear
                        </Button>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewTruck