import { Navigate } from "react-router-dom";
import { useAuth } from "../pages/login/OAuth";
import { Outlet } from "react-router-dom";

const ProtectedRoute = ({
  redirectPath = '/home',
  children,
}) => {

  const { authenticated } = useAuth()
  if (authenticated) {
    return children ? children : <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute