import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    CircularProgress,
    DialogActions,
    Typography,
    TextField,
    Grid
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

function ListTrucks() {
    const [trucks, setTrucks] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [licensePlate, setLicensePlate] = useState("");
    const [error, setError] = useState(null);
    const { api } = useAuth();

    const fetchClients = useCallback(async () => {
        try {
            const result = await api().get('/clients/');
            if (result.status < 300) {
                setClients(result.data);
                setLoading(false);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
                setLoading(false);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])

    const fetchTrucks = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            const result = await api().get("/trucks/list/active", { params });
            setTrucks(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])

    const fetchProviders = useCallback(async () => {
        try {
            const result = await api().get('/providers/');
            if (result.status < 300) {
                setProviders(result.data);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        if (trucks.length === 0 && clients.length === 0 && providers.length === 0) {
            fetchClients();
            fetchTrucks();
            fetchProviders();
        }
    }, [trucks, clients, providers]);

    const handleDeleteItem = async () => {
        try {
            const result = await api().patch(`/trucks/${deleteItemId}/disable`)
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchTrucks();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }

    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleProviderChange = (event, newValue) => {
        setSelectedProvider(newValue);
    };
    const handleLicensePlateChange = (event) => {
        setLicensePlate(event.target.value);
    };

    const filteredTrucks = trucks.filter(truck => {
        const matchesLicensePlate = truck.license_plate.toLowerCase().includes(licensePlate.toLowerCase());
        const matchesProvider = selectedProvider ? truck.provider_id === selectedProvider.id : true;
        return matchesLicensePlate && matchesProvider;
    });

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={3} sm={2}>
                            <TextField
                                fullWidth
                                label="Matrícula"
                                name="license_plate"
                                value={licensePlate || ""}
                                onChange={handleLicensePlateChange}
                            />
                        </Grid>
                        <Grid item xs={3} sm={2}>
                            <Autocomplete
                                options={providers}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={selectedProvider}
                                onChange={handleProviderChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Transportista"
                                        style={{ maxWidth: '100%' }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Matricula</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Transportista</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Acciones</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTrucks.map(truck => (
                                    <TableRow key={truck.id}>
                                        <TableCell component="th" scope="row">
                                            {truck.license_plate}
                                        </TableCell>
                                        <TableCell>
                                            {truck.provider_id ? providers.find(provider => provider.id === truck.provider_id)?.name : ''}
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/trucks/${truck.id}/update`}>
                                                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                                    <FaInfoCircle />
                                                </Button>
                                            </Link>
                                            <Button onClick={() => handleOpenDeleteDialog(truck.id)} variant="contained" color="error">
                                                <FaTrash />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>¿Estás seguro?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Volver
                                        </Button>
                                        <Button onClick={handleDeleteItem} color="error">
                                            Eliminar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <br />
                </>
            )}
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    )
}
export default ListTrucks;
