import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    Grid,
    TextField,
    Autocomplete,
    Typography,
    Checkbox,
    CircularProgress
} from "@mui/material";


function ListTruckDrivers() {
    const [truckDrivers, setTruckDrivers] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [name, setName] = useState("");
    const [sortByAlphabet, setSortByAlphabet] = useState(false);
    const [documentation, setDocumentation] = useState("");
    const { api } = useAuth();

    const fetchProviders = useCallback(async () => {
        try {
            const result = await api().get('/providers/');
            if (result.status < 300) {
                setProviders(result.data);
                setLoading(false);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
                setLoading(false);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchTruckDrivers = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/truck_drivers/list/active");
            setTruckDrivers(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchTruckDrivers();
    }, []);

    useEffect(() => {
        if (truckDrivers.length === 0 && providers.length === 0) {
            fetchTruckDrivers();
            fetchProviders();
        }
    }, [truckDrivers, providers]);

    const handleDeleteItem = async () => {
        try {
            const result = await api().patch(`/truck_drivers/${deleteItemId}/disable`)
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchTruckDrivers();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }

    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleProviderChange = (event, newValue) => {
        setSelectedProvider(newValue);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleDocumentationChange = (event) => {
        setDocumentation(event.target.value);
    };
    const handleSortByAlphabetChange = () => {
        setSortByAlphabet(!sortByAlphabet);
    };
    const filteredTruckDrivers = truckDrivers.filter(truckDriver => {
        const lowerCaseName = truckDriver.name.toLowerCase();
        const matchesName = lowerCaseName.includes(name.toLowerCase());
        const lowerCaseDocumentation = truckDriver.documentation.toLowerCase();
        const matchesDocumentation = lowerCaseDocumentation.includes(documentation.toLowerCase());
        const matchesProvider = selectedProvider ? truckDriver.provider_id === selectedProvider.id : true;
        return matchesName && matchesDocumentation && matchesProvider;
    }).sort((a, b) => {
        if (sortByAlphabet) {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        }
        return 0;
    });
    ;
    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <br></br>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={3} sm={2}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={name || ""}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item xs={3} sm={2}>
                            <TextField
                                fullWidth
                                label="Documentación"
                                name="documentation"
                                value={documentation || ""}
                                onChange={handleDocumentationChange}
                            />
                        </Grid>
                        <Grid item xs={3} sm={2}>
                            <Autocomplete
                                options={providers}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={selectedProvider}
                                onChange={handleProviderChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Transportista"
                                        style={{ maxWidth: '100%' }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item container xs={3} sm={2} alignItems="center">
                            <Checkbox
                                checked={sortByAlphabet}
                                onChange={handleSortByAlphabetChange}
                                color="primary"
                            />
                            <Typography variant="body1">Ordenar alfabéticamente</Typography>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <br></br>
                        <br></br>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Nombre</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Documentación</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Transportista</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Acciones</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTruckDrivers.map(driver => (
                                    <TableRow key={driver.id}>
                                        <TableCell component="th" scope="row">
                                            {driver.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {driver.documentation}
                                        </TableCell>
                                        <TableCell>
                                            {providers.find(provider => provider.id === driver.provider_id)?.name}
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/truck_drivers/${driver.id}/update`}>
                                                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                                    <FaInfoCircle />
                                                </Button>
                                            </Link>
                                            <Button onClick={() => handleOpenDeleteDialog(driver.id)} variant="contained" color="error">
                                                <FaTrash />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>¿Estás seguro?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Volver
                                        </Button>
                                        <Button onClick={handleDeleteItem} color="error">
                                            Eliminar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <br />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>

    )
}
export default ListTruckDrivers;