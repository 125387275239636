import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    Autocomplete,
    InputLabel,
    FormControl,
    Select,
    MenuItem
} from "@mui/material";

function NewTruckDriver() {
    const [loading, setLoading] = useState(false);
    const { api } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [providers, setProviders] = useState([]);
    const [providerSelected, setProviderSelected] = useState();
    const [truckDriverForm, setTruckDriverForm] = useState({
        name: null,
        documentation: null,
        doc_type: null,
        provider_id: null,
    });

    const fetchProviders = useCallback(async () => {
        try {
            const result = await api().get('/providers/all/active');
            if (result.status < 300) {
                setProviders(result.data);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])

    useEffect(() => {
        fetchProviders();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTruckDriverForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleProvider = (event, newValue) => {
        if (newValue) {
            setProviderSelected(newValue);
            setTruckDriverForm(prevState => ({
                ...prevState,
                provider_id: newValue.id,
            }));
        }
    };

    const createTruckDriver = async (event) => {
        event.preventDefault();
        if (!truckDriverForm.name) {
            setError("Por favor, añada un nombre");
            return;
        }
        if (!truckDriverForm.provider_id) {
            setError("Por favor, añada un transportista");
            return;
        }
        try {
            setLoading(true);

            const result = await api().post('/truck_drivers/new', truckDriverForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Camionero creado correctamente");
                    navigate(`/truck_drivers/list`);
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);

        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo camionero
                </Typography>
                <br></br>
                <form onSubmit={createTruckDriver}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nombre y apellidos"
                                    name="name"
                                    value={truckDriverForm.name}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel id="document-type-label">
                                        Documentación
                                    </InputLabel>
                                    <Select
                                        labelId="document-type-label"
                                        id="document-type"
                                        label='Tipo documentación'
                                        value={truckDriverForm.doc_type}
                                        onChange={handleChange}
                                        margin={'1'}
                                        name="doc_type"
                                        inputProps={{ id: 'document-type' }}
                                    >
                                        <MenuItem value="dni">DNI</MenuItem>
                                        <MenuItem value="nie">NIE</MenuItem>
                                        <MenuItem value="extranjero">CIF o Extranjero</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Documentación"
                                    name="documentation"
                                    value={truckDriverForm.documentation}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={providers}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={providerSelected}
                                        onChange={handleProvider}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Transportista"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            Crear
                        </Button>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewTruckDriver