import React, { useState } from "react";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from './OAuth.js'
import grahor from '../../images/grahor.png'

const apiUrl = process.env.REACT_APP_API_URL;

const defaultTheme = createTheme();

export default function SignIn() {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const [error, setError] = useState(null);

  const onChangeForm = (label, event) => {
    switch (label) {
      case "username":
        setUsername(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:

    }
  };
  const handleSubmit = async (event) => {

    event.preventDefault();

    try {
      const data = new URLSearchParams();
      data.append("username", username);
      data.append("password", password);

      await axios.post(apiUrl+'/login', data)
        .then(response => {
          if (response.data.access_token) {
            login(response.data.access_token)
            navigate('/');
          }
        })
        .catch(error => {
          setError("Usuario o contraseña incorrecta")
        });

    } catch (error) {
      throw error
    }
  };

  return (
    <ThemeProvider theme={defaultTheme} >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={grahor} alt="Logo" />
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(event) => {
                onChangeForm("username", event);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(event) => {
                onChangeForm("password", event);
              }}
            />
            <Grid container>
              <Grid item>
                <Link to={`/reset-pass`} variant="body2">
                  {"¿Has olvidado tu contraseña?"}
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: '#E66136', color: 'white', '&:hover': {backgroundColor: '#913d21'}}}
              disabled={!username || !password}
            >
              INICIAR SESIÓN
            </Button>

          </Box>
        </Box>
        {error && (
          <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
            {error}
          </Typography>
        )}
      </Container>
    </ThemeProvider>
  );
}