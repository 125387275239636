import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { TextField, Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

function UpdateTruckDriver() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [truckDriverForm, setTruckDriverForm] = useState({
        name: null,
        documentation: null,
        doc_type: null,
        provider_id: null,
        active: null,
    });
    const fetchTruckDriver = useCallback(async () => {
        try {
            const result = await api().get(`/truck_drivers/${id}`);
            setTruckDriverForm(result.data);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo camionero");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchTruckDriver();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTruckDriverForm({ ...truckDriverForm, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setTruckDriverForm({ ...truckDriverForm, active: null });
            setTruckDriverForm({ ...truckDriverForm, provider_id: null });
            const result = await api().patch(`/truck_drivers/${id}/update`, truckDriverForm);
            if (result.status < 300) {
                alert('Camionero actualizado correctamente');
                fetchTruckDriver()
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container justifyContent="center">
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Grid item xs={12} sm={8} md={6} lg={4}>

                    <Typography variant="h5" gutterBottom>
                        Actualizar camionero
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        name="name"
                                        required
                                        value={truckDriverForm.name}
                                        onChange={handleChange}
                                        margin="normal"
                                        InputLabelProps={{ shrink: !!truckDriverForm.name }}

                                    />
                                </Grid>
                                <br></br>

                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        margin={"1"}
                                        style={{ width: "100%" }}
                                    >
                                        <InputLabel id="document-type-label">
                                            Documentación
                                        </InputLabel>
                                        <Select
                                            labelId="document-type-label"
                                            id="document-type"
                                            label='Tipo documentación'
                                            value={truckDriverForm.doc_type}
                                            onChange={handleChange}
                                            margin="normal"
                                            name="doc_type"
                                            inputProps={{ id: 'document-type' }}
                                        >
                                            <MenuItem value="dni">DNI</MenuItem>
                                            <MenuItem value="nie">NIE</MenuItem>
                                            <MenuItem value="extranjero">Extranjero</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Documentación"
                                        name="documentation"
                                        value={truckDriverForm.documentation}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: !!truckDriverForm.documentation }}
                                        margin="normal"
                                    />
                                </Grid>
                                
                                <Grid item xs={12}>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                            Actualizar
                        </Button>
                    </form>
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>

    );
}

export default UpdateTruckDriver;
