import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth"
import Grid from '@mui/material/Grid';
import "dayjs/locale/es";
import { FaInfoCircle, FaTrash } from 'react-icons/fa'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    CircularProgress,
    TableHead,
    TableRow,
    Autocomplete,
    TextField,
    Paper,
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    Typography,
} from "@mui/material";
dayjs.extend(utc);

function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
}

function ListDeliveryNotes() {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [netSum, setNetSum] = useState(null);
    const [DNCounter, setDNCounter] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [loadingPDF, setLoadingPDF] = useState(false);
    const [loadingCSV, setLoadingCSV] = useState(false);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const { api } = useAuth();

    const [deliveryNoteNumber, setDeliveryNoteNumber] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    const [selectedDeliveryNoteIds, setSelectedDeliveryNoteIds] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const [fetchReset, setFetchReset] = useState(false);

    const handleDeliveryNoteNumber = (event) => {
        setDeliveryNoteNumber(event.target.value);
    };

    const handleMinDate = (date) => {
        setMinDate(date);
    };

    const handleMaxDate = (date) => {
        setMaxDate(date);
    };

    const fetchDeliveryNotes = useCallback(async () => {
        const params = {};

        if (selectedBuilding) {
            params.building_id = selectedBuilding.id;
        }
        if (selectedClient) {
            params.client_id = selectedClient.id;
        }
        if (deliveryNoteNumber) {
            params.delivery_note_number = deliveryNoteNumber;
        }
        if (selectedProduct) {
            params.product_id = selectedProduct.id;
        }

        params.type = "GE";

        if (minDate) {
            const startDateMidday = dayjs(minDate).hour(12).minute(0).second(0);
            params.min_date = startDateMidday.utc().format('YYYY-MM-DD');
        }
        if (maxDate) {
            const endDateMidday = dayjs(maxDate).hour(12).minute(0).second(0);
            params.max_date = endDateMidday.utc().format('YYYY-MM-DD');
        }

        try {
            const result = await api().get(`/delivery_notes/list/active`, { params });
            setDeliveryNotes(result.data[0]);
            setNetSum(result.data[1]);
            setDNCounter(result.data[0].length);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
            setLoading(false);
        }
    }, [selectedBuilding, selectedClient, selectedProduct, deliveryNoteNumber, minDate, maxDate, api]);

    const fetchBuildings = useCallback(async () => {
        const params = {};

        if (selectedClient) {
            params.client_id = selectedClient.id;
        }

        try {
            const result = await api().get(`/buildings`, { params });
            setBuildings(result.data);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [selectedClient, api]);

    const fetchClients = useCallback(async () => {
        const params = {};

        if (selectedBuilding) {
            params.building_id = selectedBuilding.id;
        }

        try {
            const result = await api().get(`/clients/`, { params });
            setClients(result.data);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [selectedBuilding, api]);

    const fetchProducts = useCallback(async () => {
        const params = {};

        if (selectedProduct) {
            params.product_id = selectedProduct.id;
        }

        try {
            const result = await api().get(`/articles/`, { params });
            setProducts(result.data);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [selectedProduct, api]);

    useEffect(() => {
        if (fetchReset) {
            fetchDeliveryNotes();
            setFetchReset(false);
        }
    }, [fetchReset]);

    useEffect(() => {
        if (buildings.length === 0 && clients.length === 0 && products.length === 0 && deliveryNotes.length === 0) {
            fetchBuildings();
            fetchClients();
            fetchProducts();
            fetchDeliveryNotes();
        }
    }, [buildings, clients, products, selectedBuilding, selectedClient, selectedProduct, deliveryNotes]);

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleBuilding = (event, newValue) => {
        if (newValue) {
            setSelectedBuilding(newValue);
        }
    };
    const handleProduct = (event, newValue) => {
        if (newValue) {
            setSelectedProduct(newValue);
        }
    };
    const handleClient = (event, newValue) => {
        if (newValue) {
            setSelectedClient(newValue);
        }
    };
    const handleApplyFilters = () => {
        fetchDeliveryNotes();
    };
    const handleResetFilters = () => {
        setSelectedBuilding(null);
        setSelectedClient(null);
        setSelectedProduct(null);
        setDeliveryNoteNumber(null);
        setMinDate(null);
        setMaxDate(null);
        setFetchReset(true);
    };

    const handleDeleteItem = () => {
        api().delete(`/delivery_notes/${deleteItemId}/delete`)
            .then((response) => {
                handleCloseDeleteDialog();
                fetchDeliveryNotes();
            })
            .catch((error) => {
                setError(`Error:`, error);
            });
    };
    const handleSelectAllCheckboxChange = () => {
        setSelectAllChecked(!selectAllChecked);
        if (!selectAllChecked) {
            setSelectedDeliveryNoteIds(deliveryNotes.map(item => item.id));
        } else {
            setSelectedDeliveryNoteIds([]);
        }
    };
    const handleCheckboxChange = (event, id) => {
        const stringId = id.toString();
        if (event.target.checked) {
            setSelectedDeliveryNoteIds([...selectedDeliveryNoteIds, stringId]);
        } else {
            setSelectedDeliveryNoteIds(selectedDeliveryNoteIds.filter(itemId => itemId !== stringId));
        }
    };

    const generatePDF = async () => {
        try {
            setLoadingPDF(true);
            const response = await api().post('/delivery_notes/list/selected/generate_pdf', selectedDeliveryNoteIds, { responseType: 'blob' });
            const pdfUrl = URL.createObjectURL(response.data);
            window.open(pdfUrl, '_blank');
        } catch (error) {
            setError('Error al generar el PDF');
        } finally {
            setLoadingPDF(false);
        }
    };

    const generateCSV = async () => {
        try {
            setLoadingCSV(true);
            const response = await api().post(`/delivery_notes/generate-csv/list`, selectedDeliveryNoteIds, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `grahor.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            setError('Error al generar el CSV');
        } finally {
            setLoadingCSV(false);
        }
    }

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} sm={12} container spacing={1}>
                    <Grid item xs={6} sm={3}>
                        <Autocomplete
                            options={buildings}
                            getOptionLabel={(option) => option.name?.toString()}
                            value={selectedBuilding}
                            onChange={handleBuilding}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Obras"
                                    style={{ maxWidth: '100%' }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Autocomplete
                            options={clients}
                            getOptionLabel={(option) => (option.number ? option.number : '') + '   ' + (option.name ? option.name : '')}
                            value={selectedClient}
                            onChange={handleClient}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Clientes"
                                    style={{ maxWidth: '100%' }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Autocomplete
                            options={products}
                            getOptionLabel={(option) => option.name?.toString()}
                            value={selectedProduct}
                            onChange={handleProduct}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Productos"
                                    style={{ maxWidth: '100%' }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Número albarán"
                            name="num_delivery_note"
                            value={deliveryNoteNumber || ""}
                            onChange={handleDeliveryNoteNumber}
                        />
                    </Grid>

                </Grid>

                <Grid item xs={12} container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={6} container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                                localeText={{
                                    cancelButtonLabel: "cancelar",
                                    okButtonLabel: "Ok",
                                    datePickerToolbarTitle: 'Seleccionar',
                                }}>
                                <MobileDatePicker
                                    label="Fecha de Inicio"
                                    closeOnSelect={true}
                                    name="min_date"
                                    value={minDate}
                                    onChange={handleMinDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                                localeText={{
                                    cancelButtonLabel: "cancelar",
                                    okButtonLabel: "Ok",
                                    datePickerToolbarTitle: 'Seleccionar',
                                }}>
                                <MobileDatePicker
                                    label="Fecha de Fin"
                                    name="max_date"
                                    value={maxDate}
                                    onChange={handleMaxDate}
                                    closeOnSelect={true}
                                    renderInput={(params) => <TextField {...params} />}

                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                Aplicar Filtros
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                Borrar Filtros
                            </Button>
                        </Grid>
                    </Grid>
                    {deliveryNotes.length > 0 ? (
                        <>
                            <Button variant="contained" onClick={generatePDF} disabled={loadingPDF || selectedDeliveryNoteIds.length === 0} style={{ marginTop: '20px', marginRight: '20px', backgroundColor: selectedDeliveryNoteIds.length === 0 ? '#8fd3ad' : '#4caf50', color: 'white' }}>
                                {loadingPDF ? <CircularProgress size={24} color="inherit" /> : "Generar PDF"}
                            </Button>
                            <Button variant="contained" onClick={generateCSV} disabled={loadingCSV || selectedDeliveryNoteIds.length === 0} style={{ marginTop: '20px', backgroundColor: selectedDeliveryNoteIds.length === 0 ? '#8fd3ad' : '#4caf50', color: 'white' }}>
                                {loadingCSV ? <CircularProgress size={24} color="inherit" /> : "Generar CSV"}
                            </Button>
                            <Button onClick={handleSelectAllCheckboxChange} variant="contained" color="primary" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                {selectAllChecked ? "Desmarcar todos" : "Marcar todos"}
                            </Button>
                            <Paper elevation={3} style={{ marginTop: '20px', marginLeft: '20px', padding: '10px' }}>
                                <h2>Neto total: {netSum.toLocaleString('es-ES')} kg</h2>
                            </Paper>
                            <Paper elevation={3} style={{ marginTop: '20px', marginLeft: '20px', padding: '10px' }}>
                                <h2>Cantidad albaranes: {DNCounter.toLocaleString('es-ES')} </h2>
                            </Paper>

                        </>) : (<></>)}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </div>
                )}
                {!loading && (
                    deliveryNotes.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Nº Albarán</TableCell>
                                        <TableCell>Fecha de salida</TableCell>
                                        <TableCell>Matricula camión</TableCell>
                                        <TableCell>Matricula remolque</TableCell>
                                        <TableCell>Material</TableCell>
                                        <TableCell>Obra</TableCell>
                                        <TableCell>Peso neto</TableCell>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>En navision</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deliveryNotes.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedDeliveryNoteIds.includes(item.id)}
                                                    onChange={(event) => handleCheckboxChange(event, item.id)}
                                                />
                                            </TableCell>
                                            <TableCell>{item.delivery_note_number}</TableCell>
                                            <TableCell>{formatDate(item.departure_date)}</TableCell>
                                            <TableCell>{item.order.truck.license_plate}</TableCell>
                                            <TableCell>{item.trailer && item.trailer.license_plate ? item.trailer.license_plate : 'Sin remolque'}</TableCell>
                                            <TableCell>{item.order.articles[0].article.name}</TableCell>
                                            <TableCell>{item.building.name}</TableCell>
                                            <TableCell>{item.net}</TableCell>
                                            <TableCell>{item.order.client.name}</TableCell>
                                            <TableCell>{item.in_nav ? (item.updated ? 'Sí pero sin actualizar' : 'Sí') : 'No'}</TableCell>
                                            <TableCell>
                                                <Link to={`/delivery_notes/${item.id}/details`}>
                                                    <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                                        <FaInfoCircle />

                                                    </Button>
                                                </Link>
                                                <Button onClick={() => handleOpenDeleteDialog(item.id)} variant="contained" color="error">
                                                    <FaTrash />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Grid item xs={12} container justifyContent="center" alignItems="center">
                            <Typography variant="body1">
                                No hay ningún albarán.
                            </Typography>
                        </Grid>
                    )
                )}
            </Grid>

            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}

            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>¿Estás seguro?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Volver
                    </Button>
                    <Button onClick={handleDeleteItem} color="error">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
export default ListDeliveryNotes;