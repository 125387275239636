import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    TextField,
    Button,
    Switch,
    Typography,
    Grid,
    Modal,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    Table,
    TableBody,
    TableContainer,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';

function UpdateProvider() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [trucks, setTrucks] = useState(null);
    const [truckDrivers, setTruckDrivers] = useState(null);
    const { api } = useAuth();

    const [providerForm, setProviderForm] = useState({
        name: null,
        address: null,
        postCode: null,
        city: null,
        phoneNumber: null,
        eMail: null,
        selectable: null,

    });
    const fetchProvider = useCallback(async () => {
        try {
            const result = await api().get(`/providers/${id}`);
            setProviderForm(result.data);
            setTrucks(result.data.trucks);
            setTruckDrivers(result.data.truck_drivers);
        } catch (error) {
            setError("Error obteniendo el transportista");
        }
    }, [id, api]);

    useEffect(() => {
        fetchProvider();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProviderForm({ ...providerForm, [name]: value });
    };

    const handleSelectChange = (event) => {
        const { name, checked } = event.target;
        setProviderForm(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            let emailRegex = /\S+@\S+\.\S+/;
            if (providerForm.eMail && !emailRegex.test(providerForm.eMail)) {
                setError('Email no válido');
            }

            const result = await api().patch(`/providers/${id}/update`, {
                provider_data: providerForm,
            });
            if (result.status < 300) {
                alert('Transportista actualizado correctamente');
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    const removeTruck = async (truckId) => {
        try {
            setLoading(true);

            const result = await api().patch(`/trucks/${truckId}/disable`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchProvider();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    const removeDriver = async (driverId) => {
        try {
            setLoading(true);

            const result = await api().patch(`/truck_drivers/${driverId}/disable`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchProvider();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container justifyContent="center">

            <Grid item xs={12} sm={8} md={6} lg={4}>

                <Typography variant="h5" gutterBottom>
                    Actualizar transportista
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    name="name"
                                    required
                                    value={providerForm.name}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{ shrink: !!providerForm.name }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Teléfono"
                                    name="phoneNumber"
                                    type="number"
                                    value={providerForm.phoneNumber}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{ shrink: !!providerForm.phoneNumber }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="eMail"
                                    value={providerForm.eMail}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{ shrink: !!providerForm.eMail }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Dirección"
                                    name="address"
                                    value={providerForm.address}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{ shrink: !!providerForm.address }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Código Postal"
                                    name="postCode"
                                    type="number"
                                    value={providerForm.postCode}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{ shrink: !!providerForm.postCode }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Provincia"
                                    name="city"
                                    value={providerForm.city}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{ shrink: !!providerForm.city }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Bloqueado</Grid>
                                        <Grid item>
                                            <Switch
                                                name="selectable"
                                                checked={providerForm.selectable}
                                                onChange={handleSelectChange}
                                                color="primary"
                                            />
                                        </Grid>
                                        <Grid item>No bloqueado</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                        Actualizar
                    </Button>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
                <br />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '100px' }}>
                    <div>
                        {trucks && trucks.filter(truck => truck.active).length > 0 && (
                            <>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Camiones asociados
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Matrícula</TableCell>
                                                <TableCell>Eliminar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {trucks.filter(truck => truck.active).map((truck) => (
                                                <TableRow key={truck.id}>
                                                    <TableCell>
                                                        {truck.license_plate}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => removeTruck(truck.id)}
                                                            disabled={loading}
                                                        >
                                                            <Delete />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                        <br />
                    </div>
                    <div>
                        {truckDrivers && truckDrivers.filter(driver => driver.active).length > 0 && (
                            <>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Camioneros asociados
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nombre</TableCell>
                                                <TableCell>Documentación</TableCell>
                                                <TableCell>Teléfono</TableCell>
                                                <TableCell>Eliminar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {truckDrivers.filter(driver => driver.active).map((driver) => (
                                                <TableRow key={driver.id}>
                                                    <TableCell>
                                                        {driver.name}
                                                    </TableCell>
                                                    <TableCell>{driver.documentation}</TableCell>
                                                    <TableCell>{driver.telephone_number}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => removeDriver(driver.id)}
                                                            disabled={loading}
                                                        >
                                                            <Delete />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                        <br />
                    </div>
                </div>

            </Grid>
        </Grid>

    );
}

export default UpdateProvider;
