import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useState, useCallback } from "react";
import { Modal, Button } from "@mui/material";
import { useNavigate, Link } from 'react-router-dom';

const AuthContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;
export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [username, setUsername] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const navigate = useNavigate();

    //Se ejecuta solo al iniciar el componente
    useEffect(() => {
        const storedToken = localStorage.getItem('AccessToken');
        if (storedToken) {
            const decodedToken = jwtDecode(storedToken);
            setUserRole(decodedToken.rol);
            setAuthenticated(true);
            setUsername(decodedToken.sub);

        } else {
            setAuthenticated(false);
        }
    }, []);

    //Se ejecuta cada segundo
    useEffect(() => {
        const checkTokenExpiration = () => {
            const storedToken = localStorage.getItem('AccessToken');
            if (storedToken) {
                const decodedToken = jwtDecode(storedToken);
    
                const expirationTime = new Date(decodedToken.exp * 1000);
                expirationTime.setMinutes(expirationTime.getMinutes() - 1);
                const currentTime = new Date();
                const timeUntilModal = expirationTime.getTime() - currentTime.getTime();
                setTimeLeft(timeUntilModal + 60000);
    
                if (timeUntilModal <= -60000){
                    handleLogout();
                }
                else if (timeUntilModal && timeUntilModal <= 0 && !modalOpen) {
                    setModalOpen(true);
                }
            }
        };
        const intervalId = setInterval(checkTokenExpiration, 1000);
    
        return () => clearInterval(intervalId);
    }, [modalOpen]);


    const handleLogout = useCallback(() => {
        setModalOpen(false);
        logout();
    }, []);

    const handleModalClose = () => {
        setModalOpen(false);
        setTimeLeft(null);
    };

    const handleRefreshToken = async () => {
        try {
            const storedToken = localStorage.getItem('AccessToken');
            const response = await api().post(`/refresh-token?token=${storedToken}`);
            const newToken = response.data.access_token;
            login(newToken);
            handleModalClose();
        } catch (error) {
            navigate('/sign-in');

        }
    };

    const logout = () => {
        localStorage.removeItem('AccessToken');
        setAuthenticated(false);
        setUserRole(null);
        setUsername(null);
        setTimeLeft(null);
    };

    const login = (token) => {
        localStorage.setItem('AccessToken', token);
        const decodedToken = jwtDecode(token);
        setAuthenticated(true);
        setUsername(decodedToken.sub);
        setUserRole(decodedToken.rol);
    };

    const api = () => {
        const storedToken = localStorage.getItem('AccessToken');
        const instance = axios.create({
            baseURL: apiUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': storedToken ? `Bearer ${storedToken}` : undefined,
            },
        });

        instance.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response && error.response.status === 401) {
                    navigate('/sign-in');
                }
                return Promise.reject(error);
            }
        );

        return instance;
    };

    return (
        <AuthContext.Provider value={{ authenticated, username, userRole, login, logout, api }}>
            {children}
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#FFF", padding: "20px" }}>
                    <h2 id="modal-title">Tu sesión va a expirar ¿Quieres seguir conectado?</h2>
                    <p>Te quedan {Math.floor(timeLeft / 1000)} segundos.</p>
                    <Button onClick={handleRefreshToken} variant="contained" style={{ marginRight: "10px" }}>Sí</Button>
                    <Link onClick={handleLogout} to={`/sign-in`}>
                        <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                            No
                        </Button>
                    </Link>
                </div>
            </Modal>
        </AuthContext.Provider>
    );
};
