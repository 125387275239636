import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { Autocomplete } from '@mui/material';
import DateTimePickerComponent from "../../components/DateTimePickerComponent";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/es";
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    CircularProgress
} from "@mui/material";
dayjs.extend(utc);

function UpdateDeliveryNote() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [limitExceded, setLimitError] = useState(null);
    const { id } = useParams();
    const { api } = useAuth();

    const [clients, setClients] = useState(null);
    const [clientSelected, setClientSelected] = useState(null);

    const [articles, setArticles] = useState([]);
    const [articleSelected, setArticleSelected] = useState(null);

    const [buildings, setBuildings] = useState(false);
    const [buildingSelected, setBuildingSelected] = useState(null);
    
    const [trucks, setTrucks] = useState([]);
    const [truckSelected, setTruckSelected] = useState(null);

    const [trailers, setTrailers] = useState([]);
    const [trailerSelected, setTrailerSelected] = useState(null);

    const [drivers, setDrivers] = useState([]);
    const [lastDriver, setLastDriver] = useState();
    const [driverSelected, setDriverSelected] = useState(null);

    const [provider, setProvider] = useState(null);

    const [initialTare, setInitialTare] = useState(null);

    const [orderForm, setOrderForm] = useState({
        date: null,
        client_id: null,
        building_id: null,
        article_id: null,
        truck_id: null,
        truck_driver_id: null
    });

    const [deliveryNoteForm, setDeliveryNoteForm] = useState({
        departure_date: null,
        observations: null,
        building_id: null,
        gross: null,
        trailer_id: null,
        type: "GE",
    });

    const fetchDeliveryNote = useCallback(async () => {
        try {
            const result = await api().get(`/delivery_notes/${id}`);
            if (result.status < 300) {
                const delivery_note = result.data[0]
                setDeliveryNoteForm(prevDNForm => ({ ...prevDNForm, departure_date: delivery_note.departure_date}));
                setDeliveryNoteForm(prevDNForm => ({ ...prevDNForm, observations: delivery_note.observations}));
                setDeliveryNoteForm(prevDNForm => ({ ...prevDNForm, building_id: delivery_note.building_id}));
                setDeliveryNoteForm(prevDNForm => ({ ...prevDNForm, gross: delivery_note.gross}));
                setDeliveryNoteForm(prevDNForm => ({ ...prevDNForm, trailer_id: delivery_note.trailer_id}));
                
                setOrderForm(prevOrderForm => ({ ...prevOrderForm, date: delivery_note.order.date }));
                setOrderForm(prevOrderForm => ({ ...prevOrderForm, client_id: delivery_note.order.client_id }));
                setOrderForm(prevOrderForm => ({ ...prevOrderForm, building_id: delivery_note.order.building_id }));
                setOrderForm(prevOrderForm => ({ ...prevOrderForm, article_id: delivery_note.order.articles[0].article.id }));
                setOrderForm(prevOrderForm => ({ ...prevOrderForm, truck_id: delivery_note.order.truck_id }));
                setOrderForm(prevOrderForm => ({ ...prevOrderForm, truck_driver_id: delivery_note.order.truck_driver_id }));
                
                setArticleSelected(delivery_note.order.articles[0].article);
                setBuildingSelected(delivery_note.building);
                setClientSelected(delivery_note.order.client);
                setTruckSelected(delivery_note.order.truck);
                setTrailerSelected(delivery_note.trailer);
                setDriverSelected(delivery_note.order.truck_driver);
                
                setInitialTare(delivery_note.gross - delivery_note.net);

            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
            setLoading(false);
        } catch (error) {
            setError(`Error: ${error}`);
        }
    }, [api, id]);

    const fetchBuildings = useCallback(async () => {
        try {
            const result = await api().get('/buildings/all/active');
            if (result.status < 300) {
                setBuildings(result.data);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${error}`);
        }
    }, [api]);

    const fetchClients = useCallback(async () => {
        try {
            const result = await api().get('/clients/all/active');
            if (result.status < 300) {
                setClients(result.data);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${error}`);
        }
    }, [api]);

    const fetchArticles = useCallback(async () => {
        try {
            const result = await api().get('/articles/all/active');
            if (result.status < 300) {
                setArticles(result.data);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${error}`);
        }
    }, [api]);

    const fetchTrucks = useCallback(async () => {
        try {
            if (clientSelected) {
                const result = await api().get(`/clients/${clientSelected.id}/trucks`);
                if (result.status < 300) {
                    setTrucks(result.data);
                } else {
                    setError(`Error ${result.status}: ${result.data.detail}`);
                }
            }
        } catch (error) {
            setError(`Error: ${error}`);
        }
    }, [api, clientSelected]);

    const fetchTrailersDriversProvider = useCallback(async () => {
        try {
            const result = await api().get(`/trucks/${truckSelected.id}/trailers_truck_drivers_provider`);
            if (result.status < 300) {
                setTrailers(result.data[0]);
                setDrivers(result.data[1]);
                setProvider(result.data[2]);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${error}`);
        }
    }, [api, truckSelected]);

    const fetchLastDriver = useCallback(async () => {
        try {
            const result = await api().get(`/truck_drivers/last/${truckSelected.id}`);
            if (result.status < 300) {
                if (result.data !== null) {
                    setLastDriver(result.data);
                    setOrderForm({ ...orderForm, truck_driver_id: result.data.id });
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${error}`);
        }
    }, [api, truckSelected, orderForm]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDeliveryNoteForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeGross = (event) => {
        const { name, value } = event.target;
        let limit = 15000
        if (trailerSelected) {
            if (trailerSelected.trailer_type === 'Carrillero_3_ejes_rigidos') {
                limit = 26000
            }
            else if (trailerSelected.trailer_type === 'Bañera_2_ejes') {
                limit = 39000
            }
            else if (trailerSelected.trailer_type === 'Bañera_3_ejes') {
                limit = 41000
            }
            else if (trailerSelected.trailer_type === 'Camion_15_tn') {
                limit = 15000
            }
        }
        if (value > limit) {
            setLimitError(`¡Atención! El valor ingresado supera el límite de ${limit}.`)
        }
        else {
            setLimitError(null); // Limpiar el mensaje de error si no se excede el límite
        }
        setDeliveryNoteForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleArticle = (event, newValue) => {
        if (newValue) {
            setArticleSelected(newValue);
            setOrderForm({ ...orderForm, article_id: newValue.id });
        }
    };

    const handleClient = (event, newValue) => {
        if (newValue) {
            setClientSelected(newValue);
            setOrderForm(prevState => ({
                ...prevState,
                client_id: newValue.id
            }));
        }
    };

    const handleTrailer = (event, newValue) => {
        if (newValue) {
            setTrailerSelected(newValue);
            setDeliveryNoteForm({ ...deliveryNoteForm, trailer_id: newValue.id });
            handleChangeGross({ target: { name: 'gross', value: deliveryNoteForm.gross } });
        }
    };

    const handleBuilding = (event, newValue) => {
        if (newValue) {
            setBuildingSelected(newValue);
            setOrderForm(prevState => ({
                ...prevState,
                building_id: newValue.id
            }));
            setDeliveryNoteForm(prevState => ({
                ...prevState,
                building_id: newValue.id
            }));
        }
    };

    const handleTruck = (event, newValue) => {
        if (newValue) {
            setTruckSelected(newValue);
            setOrderForm({ ...orderForm, truck_id: newValue.id });
        }
    };

    const handleDriver = (event, newValue) => {
        if (newValue) {
            setDriverSelected(newValue);
            setOrderForm({ ...orderForm, truck_driver_id: newValue.id });
        }
    };

    const handleDateTime = (e) => {
        const localDate = dayjs(e).local().format(); 
        setDeliveryNoteForm({ ...deliveryNoteForm, departure_date: localDate });
    };

    const updateDeliveryNote = async (event) => {
        event.preventDefault();
        if (clientSelected && !clientSelected.selectable) {
            setError("Por favor, póngase en contacto con la administración");
            return;
        }
        if (!orderForm.article_id) {
            setError("Por favor, añada al menos un artículo al pedido");
            return;
        }
        if (!deliveryNoteForm.departure_date) {
            setError("Por favor, seleccione una fecha de salida");
            return;
        }
        if (!deliveryNoteForm.gross) {
            setError("Por favor, seleccione el bruto");
            return;
        }
        if (clientSelected === null) {
            setError("Por favor, seleccione un cliente");
            return;
        }
        if (buildingSelected == null) {
            setError("Por favor, seleccione una obra");
            return;
        }
        if (truckSelected === null) {
            setError("Por favor, seleccione un camión");
            return;
        }
        if (trailerSelected === null) {
            setError("Por favor, seleccione un remolque");
            return;
        }
        if (driverSelected === null) {
            setError("Por favor, seleccione un camionero");
            return;
        }
        if (deliveryNoteForm.gross - truckSelected.tare <= 0) {
            setError("La cantidad bruta especificada hace que el neto sea negativo. ");
            return;
        }

        try {
            setLoading(true);

            const result = await api().patch(`/delivery_notes/${id}/update`, { new_dev_data: deliveryNoteForm, new_order_data: orderForm });

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error: ${result.data.detail}`);
                } else {
                    alert("Albarán actualizado correctamente");
                    navigate(`/delivery_notes/${id}/details`);
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchArticles();
        fetchBuildings();
        fetchClients();
        fetchTrucks();
        fetchDeliveryNote();
    }, []);

    useEffect(() => {
        if (lastDriver) {
            setDriverSelected(lastDriver);
        }
    }, [lastDriver]);

    useEffect(() => {
        if (clientSelected) {
            if (!clientSelected.selectable) {
                setError("Por favor, póngase en contacto con la administración");
            }
        }
    }, [clientSelected]);

    useEffect(() => {
        if (truckSelected) {
            fetchLastDriver();
            fetchTrailersDriversProvider();
        }
    }, [truckSelected]);


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                    <Typography component="h2" variant="h5">
                        Editar albarán
                    </Typography>
                    <br />
                    <form onSubmit={updateDeliveryNote}>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <Autocomplete
                                    options={buildings}
                                    getOptionLabel={(option) => option.name?.toString()}
                                    value={buildingSelected}
                                    onChange={handleBuilding}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Obra"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={clients}
                                    getOptionLabel={(option) => option.name?.toString()}
                                    value={clientSelected}
                                    onChange={handleClient}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cliente"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={trucks}
                                    getOptionLabel={(option) => (option.license_plate ? option.license_plate.toString() : "")}
                                    value={truckSelected}
                                    onChange={handleTruck}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Camión"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            {truckSelected && (
                                <>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={trailers}
                                            getOptionLabel={(option) => (option.license_plate ? option.license_plate.toString() : "")}
                                            value={trailerSelected}
                                            onChange={handleTrailer}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Remolque"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Transportista"
                                            name="provider"
                                            value={provider}
                                            InputLabelProps={{ shrink: !!provider }}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true, // Oculta la línea subrayada
                                                style: { cursor: 'default', color: 'rgba(0, 0, 0, 0.87)' } // Cambia el cursor y el color del texto
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={drivers}
                                            getOptionLabel={(option) => option.documentation.toString() + ' ' + option.name?.toString()}
                                            value={driverSelected}
                                            onChange={handleDriver}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Camionero"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={articles}
                                    getOptionLabel={(option) => option.name?.toString()}
                                    value={articleSelected}
                                    onChange={handleArticle}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Productos"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            {articleSelected && (
                                <Grid item xs={12}>
                                    <Typography variant="body1" color="textSecondary">
                                        Stock: {articleSelected.stock}
                                    </Typography>
                                </Grid>
                            )}
                            {trailerSelected && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Bruto"
                                            rows={4}
                                            type="number"
                                            inputProps={{ min: 1 }}
                                            name="gross"
                                            value={deliveryNoteForm.gross}
                                            onChange={handleChangeGross}
                                        />
                                    </Grid>
                                    {limitExceded && (
                                        <Typography variant="caption" style={{ color: 'red', marginLeft: '20px' }}>
                                            {limitExceded}
                                        </Typography>
                                    )}
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Tara"
                                            rows={4}
                                            type="number"
                                            name="tare"
                                            value={trailerSelected && trailerSelected.tare !== undefined ? trailerSelected.tare : initialTare}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true, // Oculta la línea subrayada
                                                style: { cursor: 'default', color: 'rgba(0, 0, 0, 0.87)' } // Cambia el cursor y el color del texto
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Neto"
                                            rows={4}
                                            type="number"
                                            name="net"
                                            value={deliveryNoteForm.gross - (trailerSelected && trailerSelected.tare !== undefined ? trailerSelected.tare : initialTare)}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true, // Oculta la línea subrayada
                                                style: { cursor: 'default', color: 'rgba(0, 0, 0, 0.87)' } // Cambia el cursor y el color del texto
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <DateTimePickerComponent
                                    required
                                    fullWidth
                                    label={"Fecha"}
                                    name="date"
                                    onChange={handleDateTime}
                                    value={deliveryNoteForm.departure_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Observaciones"
                                    multiline
                                    rows={4}
                                    name="observations"
                                    value={deliveryNoteForm.observations}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                Actualizar
                            </Button>
                        </Grid>
                    </form>
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                </Paper>
            )}

        </div>
    );
}

export default UpdateDeliveryNote