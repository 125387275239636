import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function BasicDatePicker({ value, onChange }) {
  
  const handleDateChange = (date) => {
    onChange(date);
  };

  const fecha = value ? dayjs(value) : null;


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="Fecha de nacimiento" value={fecha} onChange={handleDateChange} />
      </DemoContainer>
    </LocalizationProvider>
  );
}