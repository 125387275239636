import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
} from "@mui/material";

function NewArticle() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { api } = useAuth();


    const [articleForm, setArticleForm] = useState({
        name: null,
        unit: null,
        price: null
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setArticleForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const createArticle = async (event) => {
        event.preventDefault();
        if (!articleForm.name) {
            setError("Por favor, añada un nombre");
            return;
        }if (!articleForm.price) {
            setError("Por favor, añada un precio");
            return;
        }
        try {
            setLoading(true);

            const result = await api().post('/articles/new', articleForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Producto creado correctamente");
                    navigate(`/articles/list`);
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);

        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo producto
                </Typography>
                <br></br>
                <form onSubmit={createArticle}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    name="name"
                                    value={articleForm.name}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Precio (€)"
                                    name="price"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={articleForm.price}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Unidad"
                                    multiline
                                    requried
                                    name="unit"
                                    value={articleForm.unit}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            Crear
                        </Button>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewArticle