import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    Autocomplete,
    InputLabel,
    FormControl,
    Select,
    Switch,
    Chip,
    MenuItem
} from "@mui/material";

function NewProvider() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { api } = useAuth();

    const [providerForm, setProviderForm] = useState({
        name: null,
        address: null,
        postCode: null,
        documentation: null,
        doc_type: null,
        city: null,
        selectable: true,
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setProviderForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (event) => {
        const { name, checked } = event.target;
        setProviderForm(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const createProvider = async (event) => {
        event.preventDefault();
        try {
            if (!providerForm.name) {
                setError("Por favor, añada un nombre");
                return;
            }        
            setLoading(true);
            const result = await api().post('/providers/new', {
                provider_data: providerForm,
            });

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Transportista creado correctamente");
                    navigate(`/providers/list`);
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);

        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo transportista
                </Typography>
                <form onSubmit={createProvider}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    name="name"
                                    value={providerForm.name}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl 
                                variant="outlined"
                                margin="normal"
                                style={{ width: "100%" }}
                            >
                                    <InputLabel id="document-type-label">
                                        Documentación
                                    </InputLabel>
                                    <Select
                                        labelId="document-type-label"
                                        id="document-type"
                                        label='Tipo documentación'
                                        value={providerForm.doc_type}
                                        onChange={handleChange}
                                        margin="normal"
                                        name="doc_type"
                                        inputProps={{ id: 'document-type' }}
                                    >
                                        <MenuItem value="dni">DNI</MenuItem>
                                        <MenuItem value="nie">NIE</MenuItem>
                                        <MenuItem value="extranjero">CIF o Extranjero</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Documentación"
                                    name="documentation"
                                    value={providerForm.documentation}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Dirección"
                                    name="address"
                                    value={providerForm.address}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Código postal"
                                    name="postCode"
                                    type="number"
                                    value={providerForm.postCode}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Provincia"
                                    name="city"
                                    value={providerForm.city}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Bloqueado</Grid>
                                        <Grid item>
                                            <Switch
                                                name="selectable"
                                                checked={providerForm.selectable}
                                                onChange={handleSelectChange}
                                                color="primary"
                                            />
                                        </Grid>
                                        <Grid item>No bloqueado</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            Crear
                        </Button>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewProvider